import { render, staticRenderFns } from "./CostsIndex.vue?vue&type=template&id=778e6544&scoped=true"
import script from "./CostsIndex.vue?vue&type=script&lang=js"
export * from "./CostsIndex.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "778e6544",
  null
  
)

export default component.exports